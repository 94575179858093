export function isValidCPF (cpf) {
    if (typeof cpf !== "string") return false;
    cpf = cpf.replace(/[\s.-]*/igm, '');
    if (
        !cpf ||
        cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999"
    ) {
        return false;
    }
    let sum = 0;
    let remain = 0;
    for (var i = 1; i <= 9; i++)
        sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    remain = (sum * 10) % 11
    if ((remain === 10) || (remain === 11)) remain = 0
    if (remain !== parseInt(cpf.substring(9, 10))) return false
    sum = 0
    for (var j = 1; j <= 10; j++)
        sum = sum + parseInt(cpf.substring(j - 1, j)) * (12 - j)
    remain = (sum * 10) % 11
    if ((remain === 10) || (remain === 11)) remain = 0
    if (remain !== parseInt(cpf.substring(10, 11))) return false
    return true
}
