import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import facebook from '../../img/social/facebook.svg'
import footer from "../../img/footer5_Footer2.png";
import instagram from '../../img/social/instagram.svg'
import linkedin from '../../img/social/linkedin.svg'
import youtube from '../../img/social/youtube.svg'

import './DonationModal.scss';

const DonationModal = ({ isOpen, processing, error, closeModal }) => {
  let messageComponent;

  if (!!error) {
    messageComponent = <ErrorMessage error={error} />;
  } else if (processing) {
    messageComponent = <ProcessingDonnationMessage />;
  } else {
    messageComponent = <DonationAcknowledgmentMessage />;
  }


  return isOpen && (
    <div className="overlay">
      <div className="overlay-reference" >
        <div className="overlay-background" />
        <div className="modal-box">
          <div className="modal_header">
            <span
              aria-hidden="true"
              role="button"
              onClick={() => closeModal()}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </div>
          { messageComponent }
        </div>
      </div>
    </div>
  );
};

const DonationAcknowledgmentMessage = () => {
  return (
    <React.Fragment>
      <h1 className="header">Muito obrigado!</h1>
      <h2 className="subtitle">Compartilhe e convide seus amigos a doar também!</h2>

      <SocialNetworkLinks />
      <div className="donation-buttons">
        <a className="donation-button light-blue" href="/mes-de-doar">veja o placar do mês de doar</a>
        <a className="donation-button dark-blue" href="/">voltar para o site</a>
      </div>
    </React.Fragment>
  );
};

const ProcessingDonnationMessage = () => {
  return (
    <React.Fragment>
      <h1 className="header">Aguarde...</h1>
      <h2 className="subtitle">Sua doação está sendo processada.</h2>

      <p className="text">Enquanto isso, que tal visitar as nossas redes sociais?</p>
      <SocialNetworkLinks />
    </React.Fragment>
  );
}

const ErrorMessage = (error) => {
  const messageText = (error) => {
    switch(error) {
      case 'donor-creation-failed':
        return 'A criação do seu perfil falhou. Entre em contato com a nossa equipe para resolver o problema'
      case 'payment-info-retrieval-failed':
        return 'Não foi possível validar as informações de pagamento. Verifique se as informações inseridas estão corretas,' +
          'e caso o erro persista, entre em contato com a nossa equipe'
      case 'donation-failed':
        return 'Não foi possível efetuar a doação. Entre em contato com nossa equipe para resolver o problema'
      default:
        return 'Tente novamente mais tarde ou entre em contato com a nossa equipe';
    }
  }
  return (
    <React.Fragment>
      <h1 className="header">Ocorreu um erro inesperado</h1>
      <h2 className="subtitle">Entre em contato com nossa equipe para que possamos resolvê-lo</h2>

      <p className="text">{ messageText(error) }</p>
      <SocialNetworkLinks />
    </React.Fragment>
  );
}

const SocialNetworkLinks = () => {
  return (
    <div className="social-links">
      <a className="social" title="facebook" href="https://www.facebook.com/AmigosdaPoli/">
        <img src={facebook} alt="Facebook" />
      </a>
      <a className="social" title="instagram" href="https://www.instagram.com/amigosdapoli/?hl=en">
        <img src={instagram} alt="Instagram" />
      </a>
      <a className="social" title="linkedin" href="https://www.linkedin.com/company/amigos-da-poli/">
        <img src={linkedin} alt="linkedin" />
      </a>
      <a className="social" title="youtube" href="https://www.youtube.com/channel/UC9vEhMLL-qj-Q7619CC-q2Q">
        <img src={youtube} alt="youtube" />
      </a>
    </div>
  );
}

DonationModal.propTypes = {
  isOpen: PropTypes.bool,
  processing: PropTypes.bool,
  error: PropTypes.string,
  closeModal: PropTypes.func,
};

DonationModal.defaultProps = {
  isOpen: false,
  processing: false,
  error: null,
  closeModal: () => {},
};

export default DonationModal;
