import Form from '@rjsf/core';
import React from 'react';
import axios from 'axios';
import FormsModel from './forms.model';
import { isValidCPF } from './isValidCPF';
import DonationModal from './DonationModal.js';
import './DonationForm.scss';

const DonationForm = class extends React.Component {
  constructor(props) {
    super(props);
    let donationValue;
    if (typeof window !== 'undefined') {
      donationValue = window?.history.state?.donationValue;
    }
    const getMiddleValue = (arr) => arr[Math.ceil(arr.length/2) - 1];
    const defaultInitialValue = getMiddleValue(
      props.values.filter((value) => !isNaN(value))
    );
    const initialValue = (isNaN(donationValue)) ? defaultInitialValue : donationValue;

    this.state = {
      activeTab: 'step1',
      allowStep2: false,

      values: initialValue,
      chosen_value: initialValue,
      frequency: 'monthly',
      chosen_frequency: 'monthly',

      modal: false,

      campaign_name: 'no_campain',
      campaign_group: 'no_campaign_group',
      submited: false,
      error: '',
      payment_errors: []

    };
    this.changeActiveTab = this.changeActiveTab.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  changeActiveTab(tab) {
    this.setState({ activeTab: tab });
  }

  async handleChange(event) {
    this.setState({ ...event.formData });
  }

  buildDonor(formData) {
    let nameArr = formData.name.split(' ');

    return {
      tax_id: formData.cpf,
      email: formData.email,
      surname: nameArr.slice(1, nameArr.length).join(' '),
      name: nameArr[0],
      phone_number: formData.phone,
      course_taken: formData.course,
      course_year: formData.conclusion_year,
      referral: formData.referral,
    };
  };

  async createDonor() {
    let createdDonor = {};

    // TODO: findout why the referral is undefined
    try {
      createdDonor = await axios.post(
        `${process.env.DONATION_API_URL}/donors/`,
        ({ ...this.state.donor, referral: undefined })
      );
    } finally {
      if (createdDonor?.data?.donor_id) {
        return { donorId: createdDonor.data.donor_id };
      } else {
        console.log(`Donor could not be created: \n${createdDonor.data}`);
        return { error: 'donor-creation-failed' };
      }
    }
  }

  async getPaymentGatewayToken(formData) {
    const paymentData = {
      holder_name: formData.name_on_card,
      card_expiration: formData.expire_date,
      card_number: formData.card_number.replace(/\s/g, ''),
      card_cvv: formData.security_code,
      payment_method_code: 'credit_card',
    };

    let response;

    try {
      response = await axios.post(`${process.env.VINDI_URL}/public/payment_profiles`, paymentData,
        {
          headers: {
            Authorization: `Basic ${btoa(process.env.VINDI_PUB_KEY + ':')}`,
            'Content-Type': 'application/json'
          }
        });
    } catch (error) {
      console.log(`Payment information could not be retrieved \${error}`);
      return { error: 'payment-info-retrieval-failed' };
    }

    if(response.status == 201) return { gateway_token: response.data.payment_profile.gateway_token };
    return { error: 'payment-info-retrieval-failed' };
  }

  async createDonation(formData, paymentGatewayToken) {
    let dateArr = formData.expire_date.split('/');

    let donation = {
      donation_value: formData.values,
      visitor_id: "1",
      campaign_name: 'no_campain',
      campaign_group: 'no_campaign_group',
      name_on_card: formData.name_on_card.substr(0, 30),
      card_number: formData.card_number.replace(/\s/g, ''),
      expiry_date_month: dateArr[0],
      expiry_date_year: dateArr[1],
      card_code: formData.security_code,
      is_recurring: formData.frequency !== "unique" ? "Mensal" : "unica",
      gateway_token: paymentGatewayToken,
      referral: this.state.donor.referral,
    };

    let donationRes;
    const donationUrl = `${process.env.DONATION_API_URL}/donors/${this.state.donorId}/donations/`;
    try {
      donationRes = await axios.post(donationUrl, donation);
      return { success: 'Donation created!' };
    }
    catch (error) {
      console.log(`Donation failed\n ${error.message}`);
      return { error: 'donation-failed' };
    }
  }

  async handleSubmit(event) {
    if (this.state.activeTab === 'step1') {
      this.setState({
        donor: this.buildDonor(event.formData),
        activeTab: 'step2',
        allowStep2: true,
      });
    } else {
      this.setState({ modal: true, processing: true, submited: true });
      // Create donor's profile on the backend
      const donorCreation = await this.createDonor();
      if(donorCreation.error) {
        this.setState({ error: donorCreation.error, processing: false });
        return;
      } else {
        this.setState({ donorId: donorCreation.donorId });
      }

      // Retrieve payment information on Vindi
      const paymentGatewayTokenResult = await this.getPaymentGatewayToken(event.formData);
      if(paymentGatewayTokenResult.error) {
        this.setState({ error: paymentGatewayTokenResult.error, processing: false });
        return;
      }

      // Create the donation
      const donationCreation = await this.createDonation(event.formData, paymentGatewayTokenResult.gateway_token);
      if(donationCreation.error) {
        this.setState({ error: donationCreation.error, processing: false });
        return;
      }

      this.setState({ submited: true, processing: false, activeTab: 'step1' })
    }
  }

  ActiveTabContent(props) {
    const formData = props.formData;
    formData.chosen_frequency = formData.frequency;
    formData.chosen_value = `R$ ${formData.values}`;
    let schema = FormsModel.getStep1Configuration(props).schema;
    let buttonText = 'Continuar';
    if (props.activeTab === 'step2') {
      schema = FormsModel.getStep2Configuration(props).schema;
      buttonText = 'Concluir Doação';
    }

    const validate = function (formData, errors) {
      if (!isValidCPF(formData.cpf)) {
        errors.cpf.addError('CPF inválido');
      }
      return errors;
    };
    if (props.formData.submited) {
      if (props.formData.payment_errors.length > 0) {
        return (
          <div>
            <h1>Erro ao cadastrar cartão</h1>
            <p></p>
            <p></p>
            {

              props.formData.payment_errors.map((pe) => (
                <h3>{pe.message}</h3>
              ))
            }
            <h3>{props.formData.error}</h3>
            <a href="/donation">voltar</a>
          </div>
        )
      } else if (props.formData.error) {
        return (
          <div>
            <h3>{props.formData.error}</h3>
            <a href="/donation">voltar</a>
          </div>
        )
      } else {
        return (
          <div>
            <a href="/donation">voltar</a>
          </div>
        );
      }

    } else {
      return (
        <Form
          schema={schema}
          uiSchema={FormsModel.uiSchema}
          formData={formData}
          transformErrors={FormsModel.transformErrors}
          onChange={props.handleChange}
          onSubmit={props.handleSubmit}
          onError={console.error}
          validate={validate}
        >
          <button type="submit" disabled={props.formData.submited}>{buttonText}</button>
        </Form>
      );
    }
  }


  render() {
    const activeTabClass = (tab) => (tab === this.state.activeTab ? 'is-active' : '');
    const isTabEnabled = (tab) => (!this.state.allowStep2 && tab == 'step2') ? 'is-disabled' : 'is-enabled';

    return (
      <div>
        <DonationModal isOpen={this.state.modal} processing={this.state.processing} closeModal={() => this.setState({ modal: false })} error={this.state.error} />
        <section id="donation-form" className="section">
        <div className="container">
          <div className="level-item">
            <div className="tabs">
              <ul>
                <li className={`${isTabEnabled('step1')} ${activeTabClass('step1')}`}
                    onClick={() => this.changeActiveTab('step1')}>
                  <a>{this.props.step1.Title}</a>
                </li>
                <li className="separator text-primary">/</li>
                <li className={`${isTabEnabled('step2')} ${activeTabClass('step2')}`}
                    onClick={() => this.state.allowStep2 ? this.changeActiveTab('step2') : ''}
                    >
                  <a className={this.state.allowStep2 ? '' : "disabled"}> {this.props.step2.Title}</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="level-item">
            <this.ActiveTabContent
              activeTab={this.state.activeTab}
              content={this.props}
              formData={this.state}
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit} />
          </div>
        </div>
      </section>
      </div>

    );
  }
};

export default DonationForm;
