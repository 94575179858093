import { graphql } from 'gatsby';
import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout";
import Banner from "../components/shared/Banner";
import DonationForm from "../components/donation/DonationForm";
import './donation-page.scss';

export const DonationPageTemplate = ({
  banner, step1,
  step2, values, frequencies,
  image
}) => {
  const backgroundImage = `url(${!!image.childImageSharp ? image.childImageSharp.fluid.src : image})`;
  return (
    <div>
      <div
        id="home-banner"
        className="full-width-image margin-top-0 banner-background"
        style={{ backgroundImage }}
      >
        <div className="title">
          <h2 className="title-subheader">{banner.subtitle}</h2>
          <h1 className="title-header">{banner.title}</h1>
        </div>
      </div>
      <DonationForm step1={step1} step2={step2} values={values} frequencies={frequencies} />
    </div>
  );
}

DonationPageTemplate.prototypes = {
    step1: PropTypes.object,
    step2: PropTypes.object,
    values: PropTypes.arrayOf(PropTypes.string),
    frequencies: PropTypes.arrayOf(PropTypes.object),
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    banner: PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
    }),
}

const DonationPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;


    return (
        <Layout>
            <DonationPageTemplate
                banner={frontmatter.banner}
                frequencies={frontmatter.frequencies}
                image={frontmatter.image}
                step1={frontmatter.step1}
                step2={frontmatter.step2}
                values={frontmatter.values}
            />
        </Layout>
    )
}

DonationPage.propTypes = {

    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object
        })
    }),

}

export default DonationPage

export const donationPageQuery = graphql`

  query DonationPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        step1 {
            Title
            SubTitle
        }
        step2 {
            Title
            SubTitle
        }
        values
        banner {
          title
          subtitle
        }
        frequencies {
            id
            quantity
            unity
            text
        }
        image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      }
    }
  }

`
